@tailwind base;
@tailwind components;
@tailwind utilities;
/* .active {
  @apply bg-sky-100;
} */

aside nav ul li a {
 @apply w-full py-[11px] px-[23px] block rounded-tr-[10px] rounded-br-[10px] text-stone-800 text-[13px] font-bold;
}

.button {
  @apply bg-sky-500 text-white inline-block px-[40px] py-[19px] rounded-[6px];
}
.button:hover {
  @apply bg-sky-800 transition-all;
}
:root {
  --radio-border-color: #888;
  --radio-checked-color: #29CD9B;
  --radio-hover-color: #a3cef1;
  --radio-disabled-bg-color: #d9d9d9;
}
.form-control {
  display: flex;
  align-items: center;
}
.form-control label {
  line-height: 1;
  color: #5A5A5A;
  font-size: 18px;
}
input[type="radio"] {
  box-sizing: border-box;
  width: 14px;
  height: 14px;
  margin: 6px;
  padding: 0;
  border: 4px solid var(--radio-border-color);
  border-radius: 50%;
  appearance: none;
  background-color: transparent;
  outline: none;
}
input[type="radio"]:not(:disabled):checked {
  border-color: var(--radio-checked-color);
  /* background-color: var(--radio-checked-color); */
  background-clip: content-box;
  padding: 2px;
  /* background-image: radial-gradient(
    circle,
    var(--radio-checked-color) 0%,
    var(--radio-checked-color) 50%,
    transparent 60%,
    transparent 100%
  ); */
}
.opaque {
  opacity: 1 !important;
}
.react-images__view img {
  margin: 0 auto;
}
a.active {
  background: #cbedff;
}

header#masthead ul li a {
  padding: 10px 20px;
  border-radius: 5px;
}

ol {
  list-style: auto;
  padding-left: 20px;
  margin: 10px 0;
}
p {
  margin-bottom: 15px;
}
._loading_overlay_overlay {
  width: 150px!important;
  height: 150px!important;
  border-radius: 30px;
  background: #fff!important;
  box-shadow: 0 0 10px 0 #ddd!important;
  top: 50%!important;
  left: 50%!important;
  margin-top: -75px;
  margin-left: -200px;
}
@media only screen and (max-width: 1535px) {
  aside.sidebar-menu {
    width: 85px;
    overflow: hidden;
  }

  aside.sidebar-menu span {
      display: none;
  }

  aside.sidebar-menu a {
      display: block!important;
  }

  .partner img {
      margin-bottom: 15px;
      width: 150%;
      display: block;
      max-height: inherit!important;
      max-width: inherit;
  }

  main {
      padding-left: 80px!important;
  }
  main.depan{
    padding-left: 0px!important;
  }
  main > .container {
      margin: 20px auto;
  }
}
@media (max-width: 768px){
  main{
    padding-left: 0!important;
  }
  aside.sidebar-menu{
    width: auto;
  }
  aside.sidebar-menu a{
    display: flex!important;
  }
  aside.sidebar-menu span{
    display: block;
  }
  .partner img{
    max-height: 30px!important; 
    width: auto;
    display: inline-block;
  }
}